import React, { useEffect, useState } from "react";
import { Carousel } from "../Carousel";
import { useParams } from "react-router-dom";
import { Translations } from "../../utils/translations";
import {
  Flex,
  Group,
  Text,
  Divider,
  Stack,
  Title,
  Loader,
  Paper,
} from "@mantine/core";
import Image from "@material-tailwind/react/Image";
import NoResults from "../../assets/wowemoji.gif";
import {
  IconAddressBook,
  IconHomeCheck,
  IconChecklist,
  IconPhoneCall,
  IconMailbox,
  IconMap2,
} from "@tabler/icons-react";
import axios from "axios";
import { stateCodeMapper } from "../../utils/mapping";

export interface FetchedProperty {
  imageFiles: any;
  address: string;
  state: string;
  phoneNumber: string;
  email: string;
  description: string;
  rooms: number;
  id: string;
  propertyType: string;
  starRating: number;
  price?: number;
  images: {
    original: string;
    thumbnail: string;
  }[];
}

const PropertyDetail = (props: { messages: Translations }) => {
  const { messages } = props;
  const [cardData, setCardData] = useState<FetchedProperty>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { propertyId } = useParams();
  useEffect(() => {
    setCardData(undefined);
    setIsLoading(true);
    const fetchData = async () => {
      const result = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/prod/getall`,
        {
          filters: {
            propertyId,
          },
        }
      );
      const images = result.data.message[0]?.imageFiles.map(
        (imageFile: any) => {
          return {
            original: "https://rent0134-images.s3.amazonaws.com/" + imageFile,
            thumbnail: "https://rent0134-images.s3.amazonaws.com/" + imageFile,
          };
        }
      );
      const fetchedProperty = { ...result.data.message[0], images };
      setCardData(fetchedProperty);
      setIsLoading(false);
    };
    fetchData();
  }, [propertyId]);

  return (
    <>
      {isLoading ? (
        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
          }}
        >
          <Loader size={120} />
        </Flex>
      ) : cardData ? (
        <Flex pb={20} justify={"center"}>
          <Paper
            sx={{
              maxWidth: "380px",
              backgroundColor: "#f5f0f0",
              "@media screen and (min-width: 580px)": {
                maxWidth: "550px",
              },
            }}
            shadow="xl"
            radius="lg"
          >
            <Flex direction="column" w={"100%"} pb={30}>
              <Carousel images={cardData.images} />

              <Flex pt={40}>
                <Group
                  sx={{
                    justifyContent: "space-around",
                    border: "1px solid #999",
                    flexWrap: "nowrap",
                  }}
                  ml={10}
                  mr={10}
                  pl={10}
                  pr={10}
                  pt={20}
                  pb={20}
                  w={"100%"}
                >
                  <Stack
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <Text fw={500} size={15}>
                      {messages.price}
                    </Text>
                    <Text fw={600} size={22}>
                      ${cardData.price}
                    </Text>
                  </Stack>
                  <Divider color={"gray"} orientation={"vertical"} />
                  <Stack align="center" justify="center">
                    <Text fw={500} size={15}>
                      {messages.bedrooms}
                    </Text>
                    <Text fw={600} size={22}>
                      {cardData.rooms}
                    </Text>
                  </Stack>
                  <Divider color={"gray"} orientation={"vertical"} />
                  <Stack
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <Text fw={500} size={15}>
                      {messages.type}
                    </Text>
                    <Text fw={600} size={22}>
                      {cardData.propertyType === "Shared"
                        ? messages.shared
                        : messages.private}
                    </Text>
                  </Stack>
                </Group>
              </Flex>

              <Stack pt={20} pl={10} sx={{ gap: 0 }}>
                <Flex align={"center"} justify={"center"} mr={20}>
                  <IconMap2 size="1.5rem" />
                  <Title pl={5} order={5}>
                    {stateCodeMapper(cardData?.state)}
                  </Title>
                </Flex>
              </Stack>

              <Stack pt={10} pl={10} sx={{ gap: 0 }}>
                <Flex align={"center"}>
                  <IconHomeCheck size="1.9rem" />
                  <Title order={4}>{messages.address}</Title>
                </Flex>
                <Text fw={500} size={15}>
                  {cardData?.address}
                </Text>
              </Stack>

              <Stack pt={20} pl={10} sx={{ gap: 0 }}>
                <Flex align={"center"}>
                  <IconChecklist size="1.9rem" />
                  <Title order={4}>Description</Title>
                </Flex>
                <Text fw={500} size={15}>
                  {cardData.description}
                </Text>
              </Stack>

              <Stack pt={20} pl={10} sx={{ gap: 0 }}>
                <Flex align={"center"}>
                  <IconAddressBook size="1.9rem" />
                  <Title order={4}>{messages.contact_info}</Title>
                </Flex>

                <Flex pt={10} pl={20}>
                  <IconPhoneCall size="1.2rem" />
                  <Text pl={10} fw={500} size={15}>
                    {cardData?.phoneNumber}
                  </Text>
                </Flex>
                <Flex pt={10} pl={20}>
                  <IconMailbox size="1.2rem" />
                  <Text pl={10} fw={500} size={15}>
                    {cardData?.email}
                  </Text>
                </Flex>
              </Stack>
            </Flex>
          </Paper>
        </Flex>
      ) : (
        <>
          {!isLoading && !cardData && (
            <Flex
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px",
                flexDirection: "column",
                img: {
                  width: "fit-content",
                },
              }}
            >
              <Image
                src={NoResults}
                rounded={false}
                raised={true}
                alt="Image"
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export { PropertyDetail };
